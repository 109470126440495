import React, { useEffect, useState } from "react";
import { Row, Col, Button, Spin, notification, Dropdown, Menu, Empty } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BankingServices from "./BankingServices";
import PendingLoansHeader from "./PendingLoansHeader";
import PendingLoansCarousel from "./PendingLoansCarousel";
import PolicyCard from "../Policies/PolicyCard";
import DashboardCarousel from "./DashboardCarousel";
import { fetchPolicies } from "../../store/redux/actions/policyActions";
import RecommendedForYou from "./RecommendedForYou";
import products from "../../utils/data/products";
import { postAuthenticatedRequest } from "../../utils/api/requests/request";
import { useNotification } from "../../store/context/notification-context";
// import fetchClaimsPolicy from "../../utils/api/requests/fetchClaimsPolicy";
import {
  getSessionData,
} from "../../utils/interceptors/localStorageService";
import DepositCard from "../Policies/DepositCard";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const triggerNotification = useNotification();

  const [selectedFilter, setSelectedFilter] = useState("deposits");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const { policies, loading, error } = useSelector((state) => state.policy);
  const favProducts = products.filter(product => product.category === "loans").slice(0, 3);



  const [sessionData, setSessionData] = useState(null);

  useEffect(() => {
    const storedSessionData = getSessionData();
    if (storedSessionData) {
      setSessionData(storedSessionData);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect(() => {
  //   fetchClaimsPolicy();
  // }, []);

  useEffect(() => {
    dispatch(fetchPolicies());
  }, [dispatch]);

  useEffect(() => {
    const sessionData = getSessionData();
    const getUserData = async () => {
      try {
        const response = await postAuthenticatedRequest(
          "/api/cmd/selfservice/cmd",
          "UserProfileQuery",
          { operation: "POST" }
        );

        const result = response?.outData;
        if (result?.isSuccessful) {
          const userData = result?.data;
          const newSessionData = {
            ...sessionData,
            ...userData,
            mobileCountryCode: userData.mobileCountryCode,
            mobileNumber: userData.mobileNumber,
            emailAddress: userData.emailAddress,
          };
          setSessionData(newSessionData);
        } else {
          triggerNotification({
            type: "error",
            message: "Error",
            description: result.message,
          });
        }
      } catch (error) {
        triggerNotification({
          type: "error",
          message: "Error",
          description: error.message || "Failed to fetch user data",
        });
      }
    };

    if (!sessionData) {
      navigate("/authentication?mode=signin");
    } else if (!sessionData?.mobileNumber) {
      getUserData();
    }
  }, [triggerNotification, navigate]);

  const categories = [
    { key: "deposits", label: "Deposits" },
    { key: "loans", label: "Loans" },
    { key: "investments", label: "Investments" },
    { key: "insurance", label: "Insurance" },
  ];

  const handleFilterChange = (filter) => {
    //
    // setSelectedFilter(filter);
    setSelectedFilter(filter);
  };

  const sortedPolicies = policies
    .slice()
    .sort((a, b) => a.type.localeCompare(b.type));

  const filteredPolicies =
    sortedPolicies.filter((policy) => policy.type.includes(selectedFilter));

  const handleViewProducts = () => {
    navigate("/");
  };
  const handlePinSetUp = () => {
    navigate("/settings/security/setup-pin");
  };

  const handlePolicyClick = (policyNumber) => {
    navigate(`/policies/${policyNumber}`);
  };

  const menu = (
    <Menu>
      {categories.map((category) => (
        <Menu.Item
          key={category.key}
          onClick={() => handleFilterChange(category.key)}
        >
          {category.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  if (loading) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <Spin size='large' />
      </div>
    );
  }

  if (error) {
    notification.error({
      message: "Error",
      description: error,
    });
    console.error(error);
    return <div>An error occurred. Please try again.</div>;
  }

  return (
    <div className='mb-10 pt-0 p-4'>

      <div className='h-[52px]  pt-[6px]'>
        <div className='h-[52px]'>
          <div className=' h-[28px] text-[18px] text-[#615D5D]'>
            Good Morning,<span className=" font-bold text-[#221F1F]"> {sessionData?.firstName}</span>
          </div>
        </div>
      </div>

      <div className='mb-8'>
        {/* <BankingServices /> */}
        <Row gutter={16} >
          <div
            className='shadow-md hover:shadow-lg border border-gray-300 rounded-lg w-[335px] h-[266px] cursor-pointer mb-4 ml-2'
            onClick={handleViewProducts}
          >
            <div className='flex flex-col justify-center items-center transition-shadow duration-300 w-full'>
              <img
                src={"/dashboard.svg"}
                className='object-contain mb-6'
                alt='Insurance Modal'
              />
              <div className='pl-3 w-full'>
                <h4 className='text-[14px] font-semibold'>Need a new account?</h4>
                <p className='text-[12px] mb-2 pt-2'>
                  Open an account with us to Save, invest, borrow, or protect your future
                </p>
              </div>
            </div>
          </div>

          {!(sessionData?.isPinSet ?? true) && <div
            className='shadow-md hover:shadow-lg border border-gray-300 rounded-lg w-[335px] h-[266px] cursor-pointer mb-4 ml-4'
            onClick={handlePinSetUp}
          >
            <div className='flex flex-col justify-center items-center transition-shadow duration-300 w-full'>
              <img
                src={"/pin.svg"}
                className='object-contain mb-6'
                alt='Setup PIN'
              />
              <div className='pl-3 w-full'>
                <h4 className='text-[14px] font-semibold'>Setup Transaction PIN</h4>
                <p className='text-[12px] mb-2 pt-2'>
                  Without your transaction pin, you would not be able to carry out any transactions.
                </p>
              </div>
            </div>
          </div>
          }
        </Row>
      </div>

      {/* isPinSet */}

      {/* Pending */}
      <PendingLoansHeader />
      <PendingLoansCarousel />

      {/* My Insurance */}
      <div className='mb-10 mt-10'>
        <div className='mb-2'>
          <div className='text-[20px] font-semibold'>
            My Accounts
          </div>
        </div>

        <div className='mb-7'>
          <Row gutter={16} className='items-center'>
            {isMobile ? (
              <Col span={24}>
                <Dropdown overlay={menu} trigger={["click"]} className='w-full'>
                  <Button className='w-full' type='default'>
                    {categories.find((cat) => cat.key === selectedFilter)
                      ?.label || "Select Category"}
                  </Button>
                </Dropdown>
              </Col>
            ) : (
              categories.map((category) => (
                <Col key={category.key}>
                  <Button
                    className={
                      selectedFilter === category.key
                        ? "text-[#e5791b] border-[#e5791b] font-semibold border-2 rounded"
                        : "border-2 font-semibold rounded border-gray-300"
                    }
                    onClick={() => handleFilterChange(category.key)}
                  >
                    {category.label}
                  </Button>
                </Col>
              ))
            )}
          </Row>
        </div>

        <div className="relative ml-[-6px]">
          {filteredPolicies.length > 0 ? <DashboardCarousel
            slides={filteredPolicies.map((policy) => (
              policy.type == 'deposits' || policy.type == 'loans'
                ?
                <DepositCard
                  onClick={() => handlePolicyClick(policy.policyNumber)}
                  key={policy.policyNumber}
                  policy={policy}
                /> :
                <PolicyCard
                  onClick={() => handlePolicyClick(policy.policyNumber)}
                  key={policy.policyNumber}
                  policy={policy}
                />

            ))}
          /> : <Empty  description={`No ${selectedFilter} available`}/>
          }
        </div>
      </div>

      {/* Recommended */}
      <div className='mb-10'>
        <div className='mb-2'>
          <div className='text-[20px] font-semibold'>Recommended for you</div>
          <div className='text-[14px] font-sm'>
            We thought this might interest you
          </div>
        </div>

        <RecommendedForYou favProducts={favProducts} />
      </div>
    </div>
  );
};

export default Dashboard;
