import { Form, Input, Button } from "antd";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useFormData } from "../../store/context/form-data-context";
import { useTheme } from "../../store/context/theme-context";
import { completePasswordReset } from "../../utils/api/auth/auth";
import { useNotification } from "../../store/context/notification-context";

const ResetPasswordPage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { theme } = useTheme();
  const triggerNotification = useNotification();

  const { formData, setFormData } = useFormData();
  const [isLoading, setIsLoading] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [emailAddress, setEmailAddress] = useState("");

  // useEffect(() => {
  //   const query = new URLSearchParams(location.search);
  //   const requestIdParam = query.get("requestId");
  //   const emailParam = query.get("email");

  //   if (requestIdParam && emailParam) {
  //     setEmailAddress(emailParam);
  //     setRequestId(requestIdParam);
  //   } else {
  //     triggerNotification({
  //       type: "error",
  //       message: "Error",
  //       description: "Invalid reset password link.",
  //     });
  //   }
  // }, [location.search, triggerNotification]);

  const validatePassword = (_, value) => {
    if (
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!£$#&*%])[A-Za-z\d@!£$#&*%]{10,}$/.test(
        value
      )
    ) {
      return Promise.resolve();
    }
    return Promise.reject(
      "Password must be at least 10 characters, have upper and lower case letters, one digit (0-9), and one special character (!,£,$,#,&,*,%)."
    );
  };

  const handleResetPassword = async () => {
    try {
      setIsLoading(true);
      const dataToPost = {
        requestId: formData.requestId,
        emailAddress: formData.emailAddress,
        newPassword: formData.newPassword,
      };

      const response = await completePasswordReset(dataToPost);
      const result = response?.outData;

      if (result?.isSuccessful) {
        triggerNotification({
          type: "success",
          message: "Success",
          description: result?.message || "Password reset successful.",
        });

        navigate("/authentication?mode=signin");
      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description: result?.message,
        });
      }
    } catch (error) {
      triggerNotification({
        type: "error",
        message: "Error",
        description: error?.message || "An error occurred.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`min-h-screen flex items-center justify-center ${
        theme === "dark" ? "bg-stone-900 text-white" : ""
      }`}
    >
      <div className="flex flex-col md:flex-row items-center justify-center gap-3 p-4 max-w-[700px]">
        <div className="w-full md:w-1/2 space-y-4">
          <h2 className="text-lg font-semibold">
            Reset your password <br />{" "}
            <span className="text-sm text-gray-500 font-normal">
              Make sure your password is easy to remember
            </span>
          </h2>

          <Form
            form={form}
            onFinish={handleResetPassword}
            onFinishFailed={(event) => console.log(event)}
            className="space-y-4"
            layout="vertical"
          >
            <Form.Item
              name="newPassword"
              label="New password"
              rules={[
                {
                  required: true,
                  message: "Please input your new password!",
                },
                {
                  validator: validatePassword,
                },
              ]}
            >
              <Input.Password
                placeholder="New password"
                value={formData.newPassword}
                onChange={(e) =>
                  setFormData({ ...formData, newPassword: e.target.value })
                }
                iconRender={(visible) =>
                  visible ? (
                    <EyeOutlined style={{ color: "#E5791B" }} />
                  ) : (
                    <EyeInvisibleOutlined />
                  )
                }
              />
            </Form.Item>

            <Form.Item
              name="confirmNewPassword"
              label="Confirm your new password"
              dependencies={["newPassword"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The two passwords do not match!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Confirm your new password"
                value={formData.confirmNewPassword}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    confirmNewPassword: e.target.value,
                  })
                }
                iconRender={(visible) =>
                  visible ? (
                    <EyeOutlined style={{ color: "#E5791B" }} />
                  ) : (
                    <EyeInvisibleOutlined />
                  )
                }
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                block
                htmlType="submit"
                className="mt-6 py-2 h-full text-base shadow-none"
                disabled={isLoading}
              >
                {isLoading ? "Please wait..." : "Reset password"}
              </Button>
            </Form.Item>
          </Form>
        </div>

        <div className="w-full md:w-1/2 mt-8 md:mt-0 md:pl-10">
          <p>
            <span>
              <button className="mb-2 mr-2 focus:outline-none hover:text-[#E5791B]">
                <SafetyCertificateOutlined className="h-8" />
              </button>
            </span>
            <span className="font-semibold text-left">
              What makes a strong password?
            </span>
          </p>
          <ul className="list-disc list-inside text-gray-500 mt-4 space-y-2">
            <li>It must have numbers, uppercase, and lowercase letters</li>
            <li>It must be at least 10 characters long</li>
            <li>It must have a special character (e.g. !@#$&)</li>
            <li>The passwords must match</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
