import React from "react";

const PendingLoansHeader = () => {
  return (
    <div
      className='p-0 rounded-md'
      style={{
        width: "335px",
        height: "76px",
      }}
    >
      <div className='mb-2'>
        <h4 className='text-xl font-semibold'>My Pending Quotes</h4>
      </div>
      <div>
        <h6 className='text-sm font-normal'>
          Would you like to purchase an insurance policy?
        </h6>
      </div>
    </div>
  );
};

export default PendingLoansHeader;
