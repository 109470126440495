import { postAuthenticatedRequest } from "./request";

const fetchPoliciesData = async () => {
  try {
    const endpoint = "/api/cmd/selfservice/cmd";
    const command = "GetBankingProfileQuery";
    const data = { operation: "POST" };

    const response = await postAuthenticatedRequest(endpoint, command, data);

    if (response && response.outData.data) {
      console.log(response.outData.data);
      return response.outData.data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching policies:", error);
    return [];
  }
};

export default fetchPoliciesData;
