import { Menu, Switch, Divider } from "antd";
import { useState, useEffect } from "react";
import {
  LogoutOutlined,
  SunOutlined,
  MoonOutlined,
  HomeOutlined,
  MedicineBoxOutlined,
  OrderedListOutlined,
  TransactionOutlined,
  WifiOutlined,
  DownOutlined,
  SnippetsOutlined,
  LoginOutlined,
  DollarCircleOutlined,
  PhoneOutlined,
  UserAddOutlined,
  GlobalOutlined,
  SettingOutlined,
  ClusterOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { useTheme } from "../../store/context/theme-context";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import imgLogo from "../../assets/images/Empire_Group_Logo.png";
import darkLogo from "../../assets/images/dark-logo.png";
import { Link } from "react-router-dom";
import { logoutUser } from "../../store/redux/actions/userActions";
import LanguageSwitcher from "../../shared/languages/LanguageSwitcher";
import { useLanguage } from "../../store/context/language-context";
import { languageMap } from "../../shared/languages/LanguageSelector";
import { getSessionData } from "../../utils/interceptors/localStorageService";

const nameStyle = {
  display: 'inline-block',
  maxWidth: '120px',  // Adjust the maxWidth based on your layout
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const Sidebar = ({ onSelect, collapsed, type, selectedKey }) => {
  const { theme, toggleTheme } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { language: languageCode, showModal } = useLanguage();
  const [sessionData, setSessionData] = useState(null);
  const [initials, setInitials] = useState("");

  useEffect(() => {
    const storedSessionData = getSessionData();
    if (storedSessionData) {
      setSessionData(storedSessionData);
      const firstNameInitial = storedSessionData?.firstName
        .charAt(0)
        .toUpperCase();
      const lastNameInitial = storedSessionData?.lastName
        .charAt(0)
        .toUpperCase();
      setInitials(`${firstNameInitial}${lastNameInitial}`);
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "logoutEvent") {
        navigate("/authentication?mode=signin");
        window.location.reload();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [navigate]);

  const handleSignIn = () => {
    navigate("/authentication?mode=signin");
  };

  const handleRegister = () => {
    navigate("/segment");
  };

  const handleSignOut = () => {
    dispatch(logoutUser());
    sessionData?.userClassification === 2
      ? navigate("/admin/authentication?mode=signin")
      : navigate("/authentication?mode=signin");
  };

  const profileName =
    `${sessionData?.firstName} ${sessionData?.lastName}` || "Profile Name";
  const accountType =
    sessionData?.accountType === 1
      ? "Personal"
      : sessionData?.accountType === 2
        ? "Corporate"
        : null;
  const settingsPath = `/settings?profileType=${accountType?.toLowerCase()}`;
  const languageName = languageMap[languageCode];

  const items = [
    {
      key: collapsed ? "collapsed" : "expanded",
      style: { height: "auto" },
      className: "non-interactive",
      label: (
        <div className="flex items-center justify-between">
          {!collapsed && (
            <>
              <img
                src={theme === "dark" ? darkLogo : imgLogo}
                alt="Empire MFB"
                style={{ width: 90, height: 90, marginTop: 20 }}
              />
            </>
          )}
        </div>
      ),
    },
    ...(type === "portal"
      ? [
        {
          key: "divider1",
          width: "auto",
          label: <Divider />,
          className: "non-interactive",
        },
        {
          key: "home",
          icon: <HomeOutlined />,
          label: <Link to="/">Home</Link>,
        },
        {
          key: "signin",
          icon: <LoginOutlined />,
          label: "Sign In",
          onClick: handleSignIn,
        },
        {
          key: "register",
          icon: <UserAddOutlined />,
          label: "Register",
          onClick: handleRegister,
        },
        {
          key: "confirm-details",
          icon: <PhoneOutlined />,
          label: <Link to="confirm-details">Request a CallBack</Link>,
        },
        {
          key: "divider2",
          width: "auto",
          label: <Divider />,
          className: "non-interactive",
        },
        {
          key: "english",
          icon: <GlobalOutlined onClick={showModal} />,
          label: (
            <div>
              <p
                type="text"
                onClick={showModal}
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                {languageName}
              </p>
              <LanguageSwitcher />
            </div>
          ),
        },
        {
          key: "theme",
          icon:
            theme === "dark" ? (
              <MoonOutlined onClick={toggleTheme} />
            ) : (
              <SunOutlined onClick={toggleTheme} />
            ),
          label: (
            <div className="flex items-center justify-between">
              <span className="mr-2">Dark Mode</span>
              <Switch
                checked={theme === "dark"}
                onChange={toggleTheme}
                size="small"
              />
            </div>
          ),
        },
      ]
      : []),
    ...(type === "inner"
      ? [
        {
          key: "profile",
          className: "non-interactive",
          style: { height: "auto" },
          label: !collapsed && (
            <div
              className={`${theme === "dark" ? "text-white bg-stone-900" : "bg-[#F7F7F7]"
                } flex items-center justify-start pl-1 pr-8 py-3 rounded-lg my-7 mx-0 gap-3`}
            >
              <div className="flex-shrink-0">
                <div className="w-12 h-12 bg-[#E5791B] text-white flex items-center justify-center rounded-full text-lg">
                  {initials || "PN"}
                </div>
              </div>
              <div className="h-12 text-base flex-1">
                <p>
                  <span
                    className="font-semibold block"
                    style={nameStyle}
                  >
                    {profileName || "Profile Name"}
                  </span>
                  <span className="text-gray-500 text-sm block">
                    {accountType}
                  </span>
                </p>
              </div>
              <div className="ml-[3px] mr-4">
                <DownOutlined className="text-[#E5791B]" />
              </div>
            </div>

          ),
        },

        ...(sessionData?.userClassification === 2
          ? [
            {
              key: "adminDashboard",
              icon: <HomeOutlined />,
              label: <Link to="/admin-dashboard">Dashboard</Link>,
            },
            {
              key: "AdminCustomers",
              icon: <UsergroupAddOutlined />,
              label: <Link to="/customers">Customers</Link>,
            },
            {
              key: "AdminTransactions",
              icon: <SnippetsOutlined />,
              label: <Link to="/transactions">Transactions</Link>,
            },
            {
              key: "AdminTransfers",
              icon: <TransactionOutlined />,
              label: <Link to="/transfers">Transfers</Link>,
            },
            {
              key: "AdminRequests",
              icon: <OrderedListOutlined />,
              label: <Link to="/statement-request">Requests</Link>,
            },
            {
              key: "AdminOrganisation",
              icon: <ClusterOutlined />,
              label: <Link to="/statement-request">Organisation </Link>,
            },
            {
              key: "AdminProductConfiguration",
              icon: <SettingOutlined />,
              label: (
                <Link to="/statement-request">Product Configuration</Link>
              ),
            },
          ]
          : [
            {
              key: "dashboard",
              icon: <HomeOutlined />,
              label: <Link to="/dashboard">Dashboard</Link>,
            },
            {
              key: "Transfer",
              icon: <TransactionOutlined />,
              label: <Link to="/transfer-type">Transfer</Link>,
            },
            {
              key: "AirtimeRecharge",
              icon: <PhoneOutlined />,
              label: <Link to="/airtime">Airtime Recharge</Link>,
            },
            {
              key: "DataRecharge",
              icon: <WifiOutlined />,
              label: <Link to="/data">Data Recharge</Link>,
            },
            {
              key: "BillsPayment",
              icon: <MedicineBoxOutlined />,
              label: <Link to="/bills-payment">Bills Payment</Link>,
            },

            {
              key: "loans",
              icon: <DollarCircleOutlined />,
              label: <Link to="/loan/types">My Loans</Link>,
            },
            {
              key: "StatementRequest",
              icon: <OrderedListOutlined />,
              label: <Link to="/statement-request">Statement Request</Link>,
            },
          ]),
        {
          key: "divider3",
          width: "auto",
          label: <Divider />,
          className: "non-interactive",
        },
        {
          key: "settings",
          icon: <SettingOutlined />,
          label: <Link to={settingsPath}>Settings & More</Link>,
        },
        {
          key: "english",
          icon: <GlobalOutlined />,
          label: (
            <div>
              <p
                type="text"
                onClick={showModal}
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                {languageName}
              </p>
              <LanguageSwitcher />
            </div>
          ),
        },
        {
          key: "theme",
          icon:
            theme === "dark" ? (
              <MoonOutlined onClick={toggleTheme} />
            ) : (
              <SunOutlined onClick={toggleTheme} />
            ),
          label: (
            <div className="flex items-center justify-between">
              <span className="mr-2">Dark Mode</span>
              <Switch
                checked={theme === "dark"}
                onChange={toggleTheme}
                size="small"
              />
            </div>
          ),
        },
        {
          key: "divider4",
          width: "auto",
          label: <Divider />,
          className: "non-interactive",
        },
        {
          key: "signout",
          icon: <LogoutOutlined />,
          label: "Sign Out",
          onClick: handleSignOut,
        },
      ]
      : []),
  ];

  return (
    <Menu
      onClick={({ key }) => onSelect(key)}
      selectedKeys={[selectedKey]}
      defaultSelectedKeys={["home", "dashboard"]}
      mode="inline"
      style={{
        height: "100vh",
        overflow: "auto",
      }}
      items={items}
    />
  );
};

export default Sidebar;

//
//
