import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { ConfigProvider, theme, App, notification } from "antd";
import { useTheme } from "./store/context/theme-context";
import { getSubdomain } from './utils/routes/baseURLRetriver'; // Adjust the path as necessary
import {
  authenticateUser,
  setStatus,
  setToken,
} from "./store/redux/features/authSlice";
import { OtpProvider } from "./store/context/otp-context";
import AppRoutes from "./shared/routes/AppRoutes";
import { FormDataProvider } from "./store/context/form-data-context";
import { NotificationProvider } from "./store/context/notification-context";
import { logoutUser } from "./store/redux/actions/userActions";
import { getSessionData } from "./utils/interceptors/localStorageService";

const MyApp = () => {
  const dispatch = useDispatch();
  const { theme: selectedTheme } = useTheme();
  const sessionData = getSessionData();
  const isLoggedIn = !!sessionData;

  const lightTheme = selectedTheme === "light";


  // Extract the subdomain
  const subdomain = getSubdomain();

  // Set the document title based on the subdomain
  if (subdomain == 'citygate') {
    document.title = `Welcome to City Gate Investment`;
  } else {
    document.title = 'Welcome to Empire Trust MFB';
  }


  const idleTimeoutRef = useRef(null);
  const logoutTimeRef = useRef(null);

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const authStatus = localStorage.getItem("authStatus");

    if (authToken) {
      dispatch(setToken(authToken));
    }

    if (authStatus) {
      dispatch(setStatus(authStatus));
    } else {
      dispatch(authenticateUser());
    }
  }, [dispatch]);

  // Inactivity handling
  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     return;
  //   }

  //   const handleUserActivity = () => {
  //     if (idleTimeoutRef.current) {
  //       clearTimeout(idleTimeoutRef.current);
  //     }
  //     if (logoutTimeRef.current) {
  //       clearTimeout(logoutTimeRef.current);
  //     }

  //     idleTimeoutRef.current = setTimeout(() => {
  //       notification.warning({
  //         message: "Inactivity Alert",
  //         description:
  //           "You have been inactive for 30 seconds. You will be logged out in the next 30 seconds if there is no further activity.",
  //         duration: null,
  //       });

  //       logoutTimeRef.current = setTimeout(() => {
  //         dispatch(logoutUser());
  //         window.location.href = "/authentication?mode=signin";
  //       }, 30000); // 30 seconds after the first notification
  //     }, 30000); // 30 seconds of inactivity
  //   };

  //   const events = [
  //     "mousemove",
  //     "mousedown",
  //     "keydown",
  //     "scroll",
  //     "touchstart",
  //   ];

  //   events.forEach((event) =>
  //     window.addEventListener(event, handleUserActivity)
  //   );

  //   // Cleanup function
  //   return () => {
  //     events.forEach((event) =>
  //       window.removeEventListener(event, handleUserActivity)
  //     );
  //     if (idleTimeoutRef.current) {
  //       clearTimeout(idleTimeoutRef.current);
  //     }
  //     if (logoutTimeRef.current) {
  //       clearTimeout(logoutTimeRef.current);
  //     }
  //   };
  // }, [dispatch, isLoggedIn]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#e5791b",
        },
        algorithm: lightTheme ? theme.defaultAlgorithm : theme.darkAlgorithm,
      }}
    >
      <App>
        <NotificationProvider>
          <FormDataProvider>
            <OtpProvider>
              <AppRoutes />
            </OtpProvider>
          </FormDataProvider>
        </NotificationProvider>
      </App>
    </ConfigProvider>
  );
};

export default MyApp;
