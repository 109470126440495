import gleImg from "../../assets/images/funeral-expense.jpg";
import glaImg from "../../assets/glaImg.png";
import edu from "../../assets/edu.jpg";
import goal from "../../assets/goal.png";
import ciImg from "../../assets/ciImg.jpg";
import happyfamily from "../../assets/happyfamily.png";
import groupcreditImg from "../../assets/groupcredit.png";
import annuityImg from "../../assets/annuityImg.jpg";
import motorImg from "../../assets/images/motorImg.jpg";
import healthImg from "../../assets/healthImg.jpg";

const products = [
  {
    title: "Funeral Expense Cover",
    url: "/funeral-expense/select-customer-type",
    image: gleImg,
    description:
      "Funeral expense solution is designed to help families deal with the burden of meeting immediate funeral related expenses on the death of a family member.",
    category: "funeral",
    type: "funeralexpense",
  },
  {
    title: "Critical Illness Cover",
    image: ciImg,
    url: "customer-type",
    description:
      "Critical Illness insurance provides comprehensive coverage for against chronic illnesses such as cancer, ensuring you receive the best healthcare possible.",
    category: "medical",
    type: "criticalillness",
  },
    {
    title: "Education Insurance Cover",
    url: "education",
    image: edu,
    description:
      "An education insurance cover is a financial plan ensuring funds for a child's education, providing savings and protection against uncertainties like parent's death or disability.",
    category: "education",
    type: "educationcover",
  },
  {
    title: "Pay Day Loan",
    url: "loan-request/pay-day-loan",
    image: glaImg,
    description:
      "This loan is designed to assist salaried employees in private organizations by offering quick access to funds before payday. It helps manage urgent expenses, ensuring financial stability during the gap between pay cycles. Ideal for those seeking short-term relief, it provides a convenient solution for unexpected financial needs.",
    category: "loans",
    type: "grouplife",
  },

  {
    title: "SME Loans",
    url: "loan-request/sme-loans",
    image: happyfamily,
    description:
      "Small Medium Enterprise (SME) loan designed to enable small entrepreneurs, access fund to finance a variety of business needs – working capital, FMCGs, supply (LPO) etc., with minimum loan amount of N800,000. Loan facility is for traders and business men who have an existing business and making sales on regular basis.",
    category: "loans",
    type: "termlife",
  },
  {
    title: "Assets Finance Loan",
    url: "loan-request/assets-finance-funds",
    image: goal,
    description:
      "This product is aimed at business enterprises needing machinery and physical assets for expansion, upgrading, or diversification. It offers financial support for acquiring fixed assets, helping businesses grow and improve. Ideal for companies seeking long-term investment in their operations.",
    category: "loans",
  },
  {
    title: "Proof Of Funds (POF)",
    image: groupcreditImg,
    url: "loan-request/proof-of-funds",
    description:
      "This facility is designed to meet the urgent needs of individuals traveling abroad for education or invitation purposes. It offers quick financial support to cover travel expenses, ensuring a smooth and timely process for those pursuing academic opportunities or important events overseas.",
    category: "loans",
    type: "groupcredit",
  },
  {
    title: "PAYROLL LOAN",
    url: "loan-request/payroll-loan",
    image: annuityImg,
    description:
      "This loan facility is designed to address urgent needs and bridge liquidity gaps for salary earners in public establishments. Structured against monthly salaries, it serves civil servants in government entities, including Lagos State Workers, Police, Customs, Tertiary Institution Staff, FRSC.",
    category: "loans",
    type: "annuitypolicy",
  },
  {
    title: "Motor Vehicle Insurance",
    url: "/motor-vehicle/select-motor-use",
    image: motorImg,
    description:
      "Get insurance protection for your motor vehicle against accidental loss or damage, including the spare parts and standard accessories, as well as protection against legal liability to third parties.",
    category: "motor",
    type: "motorcover",
  },
  {
    title: "Health Insurance Cover",
    url: "personal-customer",
    image: healthImg,
    description:
      "Get comprehensive health insurance cover that protects you and your family against a wide range of diseases and medical conditions. Our plan includes hospitalization benefits, outpatient services, emergency care, and preventive healthcare.",
    category: "medical",
    type: "healthcover",
  },
];

export default products;
