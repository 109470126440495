import sspFlag from "../../assets/flags/ssp.png";
import njaFlag from "../../assets/flags/9ja.png";
import cdfFlag from "../../assets/flags/cdf.png";
import rwfFlag from "../../assets/flags/rwf.png";
import kesFlag from "../../assets/flags/kes.png";
import tzsFlag from "../../assets/flags/tzs.png";
import ugxFlag from "../../assets/flags/ugx.png";

export const countryData = [
  {
    id: 1,
    name: "Nigeria",
    flag: njaFlag,
    phone: "+234 911 9986 000",
    email: "info-nigeria@equitybank.co.ke",
    facebookLink: "https://www.facebook.com/SsEquityBank/",
    websiteLink: "https://www.ss.equitybankgroup.com/",
  },
  {
    id: 1,
    name: "South Sudan",
    flag: sspFlag,
    phone: "+211 916 986 000",
    email: "info-southsudan@equitybank.co.ke",
    facebookLink: "https://www.facebook.com/SsEquityBank/",
    websiteLink: "https://www.ss.equitybankgroup.com/",
  },
  {
    id: 2,
    name: "Congo (Democratic Republic of Congo)",
    flag: cdfFlag,
    phone: "+243 996 018 000",
    email: "mail@equitybcdc.cd",
    twitterLink: "https://x.com/EquityBCDC?lang=en",
    facebookLink: "https://www.facebook.com/EquityBCDC/",
    linkedinLink: "https://www.linkedin.com/company/equitybcdc/",
    websiteLink: "https://equitygroupholdings.com/cd/",
  },
  {
    id: 3,
    name: "Rwanda",
    flag: rwfFlag,
    phone: "+250 788 190 000",
    email: "inforwanda@equitybank.co.rw",
    twitterLink: "https://x.com/kwequitybank?lang=en",
    facebookLink: "https://www.facebook.com/RwEquityBank/?ref=page_internal",
    websiteLink: "https://equitygroupholdings.com/rw/",
  },
  {
    id: 4,
    name: "Kenya",
    flag: kesFlag,
    phone: "+254 763 000 000",
    email: "info@equitybank.co.ke",
    twitterLink: "https://x.com/keequitybank?lang=en",
    facebookLink: "https://www.facebook.com/KeEquityBank/?ref=page_internal",
    linkedinLink:
      "https://www.linkedin.com/company/equity-bank-limited/?originalSubdomain=ke",
    websiteLink: "https://equitygroupholdings.com/",
  },
  {
    id: 5,
    name: "Tanzania",
    flag: tzsFlag,
    phone: "+255 768 985 500",
    email: "infotz@equitybank.co.tz",
    twitterLink: "https://x.com/TZEquityBank?lang=en",
    facebookLink: "https://www.facebook.com/TzEquityBank/",
    websiteLink: "https://equitygroupholdings.com/tz/",
  },
  {
    id: 6,
    name: "Uganda",
    flag: ugxFlag,
    phone: "+256 312 327 000",
    email: "info@equitybank.co.ug",
    twitterLink: "https://x.com/UgEquityBank?lang=en",
    facebookLink: "https://www.facebook.com/UgEquityBank/",
    websiteLink: "https://equitygroupholdings.com/ug/",
  },
];
