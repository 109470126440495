import { Typography } from "antd";
import imgLogo from "../../assets/images/dark-logo.png";
import bgLogo from "../../assets/images/authImage.jpg";
import bgLogoDark from "../../assets/images/authImageDark.png";
import { useTheme } from "../../store/context/theme-context";
import LanguageSelector from "../../shared/languages/LanguageSelector";
import { CommentOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import CountrySupportModal from "../../shared/common/CountrySupportModal";
import { getSubdomain } from '../../utils/routes/baseURLRetriver';

const { Title, Text } = Typography;

const AuthLayout = ({ children }) => {
  const { theme } = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleOkay = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [subdomain, setSubdomain] = useState(null);

  useEffect(() => {
    const sub = getSubdomain();
    console.log("sub",sub );
    // You can add logic here to customize the UI based on the subdomain
    if (sub === 'citygate') {
      // Set UI or perform actions for subdomain 'x'
      setSubdomain(
        "City Gate Investment."
      );
    }else{
      setSubdomain(
        "Empire Trust MFB."
      );
    }
  }, []);
  const [collapsed, setCollapsed] = useState(window.innerWidth < 768);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {!collapsed && (
        <div className="flex flex-row min-h-screen w-full overflow-hidden">
          {/* Left Side */}
          <div
            className="flex flex-col w-3/5 min-h-screen relative bg-cover bg-center"
            style={{
              backgroundImage: `url(${theme === "dark" ? bgLogoDark : bgLogo})`, // Conditional background image
              position: "fixed", // Fixed positioning for the left side
              top: 0, // Stick to the top of the viewport
              bottom: 0, // Take full height of the viewport
              left: 0, // Stick to the left side of the viewport
            }}
          >
            <img
              src={imgLogo}
              alt="Logo"
              className="absolute top-0 left-0 ml-16 mt-12 w-30 h-20"
            />
            <div className="text-left ml-16 mt-40">
              <Title level={1} style={{ color: "white" }}>
                Welcome to <br />
                {subdomain}
              </Title>
              <Text className="text-[20px] text-white">
                Sign in or register to continue
              </Text>
            </div>
          </div>

          {/* Right Side */}
          <div
            className={`ml-[60%] w-2/5 min-h-screen overflow-y-auto ${
              theme === "dark" ? "bg-stone-900 text-white" : ""
            } p-4`}
            style={{ paddingBottom: "80px" }}
          >
            {children}

            <div
              className={`fixed bottom-0 w-[40%] p-5 ${
                theme === "dark" ? "bg-stone-900 text-white" : "bg-[#fff]"
              }`}
            >
              <div className="flex flex-row items-center justify-between">
                <div className="w-1/2">
                  <LanguageSelector />
                </div>
                <div
                  className="flex items-center justify-center rounded-full bg-gray-300 p-2 cursor-pointer"
                  onClick={openModal}
                >
                  <CommentOutlined className="text-[#E5791B] text-2xl" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {collapsed && (
        <div
          className={`min-h-screen overflow-y-auto ${
            theme === "dark" ? "bg-stone-900 text-white" : ""
          }`}
          style={{ paddingBottom: "80px" }}
        >
          {children}
          <div
            className={`fixed bottom-0 w-full p-5 ${
              theme === "dark" ? "bg-stone-900 text-white" : "bg-[#fff]"
            }`}
          >
            <div className="flex flex-row items-center justify-between">
              <div className="w-1/2">
                <LanguageSelector />
              </div>
              <div
                className="flex items-center justify-center rounded-full bg-gray-300 p-2 cursor-pointer"
                onClick={openModal}
              >
                <CommentOutlined className="text-[#E5791B] text-2xl" />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal should be available in both views */}
      <CountrySupportModal
        isModalOpen={isModalOpen}
        handleOkay={handleOkay}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default AuthLayout;
