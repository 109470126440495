import React from "react";

const BankingServices = ({ title }) => {
  return (
    <div className='mb-5 w-[335px] h-[52px] pt-[6px] pb-[6px]'>
      <div className='w-[335px] h-[52px] pt-[6px] pb-[6px]'>
        <div className='w-[335px] h-[28px] text-[20px] font-bold leading-[28px] text-left text-[#221F1F]'>
          {title ?? 'Banking Services'}
        </div>
      </div>
    </div>
  );
};

export default BankingServices;
