import { useState, useEffect } from "react";
import { Form, Input, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { initiateResetPassword } from "../../utils/api/auth/auth";
import { useNotification } from "../../store/context/notification-context";
import { useFormData } from "../../store/context/form-data-context";

const ForgotPasswordPage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const triggerNotification = useNotification();
  const { setFormData } = useFormData();

  const [isLoading, setIsLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);

  const handleNavigate = () => {
    navigate("/authentication?mode=signin");
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await initiateResetPassword({ emailAddress });
      const result = response?.outData;

    if (response?.ok && result?.data) {
        setFormData((prev) => ({
          ...prev,
          requestId: result?.data?.requestId,
          userId: result?.data?.userId,
          emailAddress: result?.data?.emailAddress,
        }));

        navigate("/forgot-password/otp-verify", {
          state: { url: "/reset-password" },
        });
      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description:
            result?.message ||
            "Failed to initiate password reset. Please check your email address and try again.",
        });
      }
    } catch (error) {
      triggerNotification({
        type: "error",
        message: "Error",
        description:
          error?.message || "An error occurred. Pleased try again later",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const validateEmail = async () => {
      try {
        await form.validateFields(["emailAddress"]);
        setIsEmailValid(true);
      } catch (error) {
        setIsEmailValid(false);
      }
    };
    validateEmail();
  }, [emailAddress, form]);

  return (
    <div className="p-4">
      <div className="mb-4 mt-16">
        <span>
          <button className="focus:outline-none hover:text-[#E5791B]">
            <LeftOutlined className="mr-6" onClick={handleNavigate} />
          </button>
        </span>
        <span className="text-lg font-semibold leading-[24px] text-left">
          Forgot password
        </span>
      </div>
      
      <div className="mt-6 mb-8">
        <p className="text-2xl font-semibold text-left mb-3">
          Have you forgotten your password?
        </p>
        <p className="text-[#929497] text-left">
          Enter your email address we will send you an otp to help reset your
          password (Ensure this email address matches the one in your account).
        </p>
      </div>
      <Form
        form={form}
        layout="vertical"
        onFinishFailed={(event) => console.log(event)}
        onFinish={handleSubmit}
      >
        <Form.Item
          label="Email Address"
          name="emailAddress"
          rules={[
            {
              required: true,
              message: "Please enter your email address.",
            },
            {
              type: "email",
              message: "Please enter a valid email address.",
            },
          ]}
        >
          <Input
            placeholder="Enter your email address"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
          />
        </Form.Item>

        <div className="text-left mt-4">
          <Button
            type="primary"
            className="shadow-none px-5 py-2 h-full text-base"
            htmlType="submit"
            disabled={!isEmailValid}
            loading={isLoading}
          >
            {isLoading ? "Submitting..." : "Continue"}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ForgotPasswordPage;
