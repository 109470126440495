import React, { useState, useEffect } from "react";
import { Layout, Divider, Typography, Row, Dropdown, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import ProductsList from "../components/InsuranceProducts/ProductsList";
import products from "../utils/data/products";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const { Text } = Typography;
const { Content } = Layout;

const Home = () => {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const productCategories = [
    { name: "all" },
    { name: "loans" },
    { name: "medical" },
    { name: "personal" },
    { name: "education" },
    { name: "funeral" },
    { name: "life" },
  ];

  const [selectedCategory, setSelectedCategory] = useState("all");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  const handleSelectCategory = (category) => {
    setSelectedCategory(category);
  };

  const handleNavigate = () => {
    navigate(-1);
  };

  const getButtonClass = (category) => {
    return selectedCategory === category
      ? "border-[#E5791B] font-semibold border-2 bg-white"
      : "border-2 font-semibold";
  };

  const getTextClass = (category) => {
    return selectedCategory === category ? "text-[#E5791B]" : "text-gray-800";
  };

  const filteredProducts =
    selectedCategory === "all"
      ? products
      : products.filter((product) => product.category === selectedCategory);
 
      useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth < 1200);
        };
    
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);
    
      const menuItems = productCategories.map((category) => ({
        key: category.name,
        label:
          category.name === "all"
            ? "All"
            : `${
                category.name.charAt(0).toUpperCase() + category.name.slice(1)
              } Insurance`,
        onClick: () => handleSelectCategory(category.name),
      }));
    
  return (
    <Layout className="layout  p-4">
      {/* <div className="mb-4 flex items-center">
        {isLoggedIn && (
          <button
            className="mr-4 ml-[-10px] focus:outline-none hover:text-[#E5791B] text-[#221F1F] text-lg"
            onClick={handleNavigate}
          >
            <LeftOutlined className="text-xl" />
          </button>
        )}
         <Row gutter={[16, 16]}>
          <span className="font-open-sans text-[16px] font-semibold leading-[24px]">
            {t("Get insurance")}
          </span>
        </Row>
      </div> */}
      <Row gutter={[16, 16]}>
        <div className="mt-1">
          <div style={{ marginBottom: "15px" }}>
            <p className="text-xl font-bold">
              {t("Apply for loans")}
            </p>
          </div>
          <div className="">
            <p className="text-base">
              {t(
                "We offer affordable payroll loan, payday loan, SMEs loan, and Proof of fund loans that protects you and your loved ones against financial loss."
              )}
            </p>
          </div>
        </div>
      </Row>

      <Content>
      <div className="ml-1 mt-4 sm:mt-6 md:mt-8 lg:mt-4 xl:mt-4">
          {isMobile ? (
            <Dropdown
              menu={{
                items: menuItems,
              }}
              trigger={["click"]}
              className="w-full font-semibold"
            >
              <Button className="w-full" type="default">
                {productCategories.find((cat) => cat.name === selectedCategory)
                  ?.name === "all"
                  ? "All" : selectedCategory.name === "loans" ? 'Loans' 
                  : `${
                      selectedCategory.charAt(0).toUpperCase() +
                      selectedCategory.slice(1)
                    } Insurance`}
              </Button>
            </Dropdown>
          ) : (
            <div className="flex items-center flex-wrap space-x-2">
              {productCategories.map((category, index) => (
                <button
                  key={index}
                  className={`px-2 py-1 mt-1 ${getButtonClass(
                    category.name
                  )} rounded`}
                  onClick={() => handleSelectCategory(category.name)}
                >
                  <Text strong className={getTextClass(category.name)}>
                    {category.name === "all"
                      ? "All" : category.name === "loans" ? 'Loans' 
                      : `${
                          category.name.charAt(0).toUpperCase() +
                          category.name.slice(1)
                        } Insurance`}
                  </Text>
                </button>
              ))}
            </div>
          )}
        </div>
        <Divider className="bg-[#D3D4D5]" />
        <ProductsList products={filteredProducts} />
      </Content>
    </Layout>
  );
};

export default Home;
