import { postRequest } from "../requests/request";

// Function to preregister a user
export const preRegisterUser = async (data) => {
  return postRequest("/api/cmd/cmd", "PreRegistrationCommand", data);
};

// Function to validate OTP
export const validateOTP = async (data) => {
  return postRequest("/api/cmd/cmd", "ValidateOtpCommand", data);
};

export const preRegisterCorporateUser = async (data) => {
  return postRequest("/api/cmd/cmd", "PreCoorporateRegistrationCommand", data);
};

// Function to resend OTP
export const resendOTP = async (data) => {
  return postRequest("/api/cmd/cmd", "ResendOtpCommand", data);
};

// Function to complete registration
export const completeRegistration = async (data) => {
  return postRequest("/api/cmd/cmd", "CompleteRegistrationCommand", data);
};

// Function to login a user
export const loginUser = async (data) => {
  return postRequest("/api/cmd/cmd", "SelfLoginCommand", data);
};

// Function to initiate password reset
export const initiateResetPassword = async (data) => {
  return postRequest("/api/cmd/cmd", "InitiatePasswordResetCommand", data);
};

// Function to complete password reset
export const completePasswordReset = async (data) => {
  return postRequest("/api/cmd/cmd", "CompletePasswordResetCommand", data);
};
