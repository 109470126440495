import { lazy } from "react";

export const ProtectedUserRoutes = [
  {
    path: "policies/*",
    element: lazy(() => import("../../components/Routes/PoliciesRoutes")),
  },
  {
    path: "policies/:policyId",
    element: lazy(() => import("../../components/Policies/Policy")),
  },
  {
    path: "/:quoteId",
    element: lazy(() => import("../../components/Dashboard/Quote")),
  },
  {
    path: "claims",
    element: lazy(() => import("../../components/Claims/Claims")),
  },

  {
    path: "airtime",
    element: lazy(() => import("../../pages/Airtime/AirtimeType")),
  },

  {
    path: "airtime-form",
    element: lazy(() => import("../../pages/Airtime/AirtimeForm")),
  },

  {
    path: "data-form",
    element: lazy(() => import("../../pages/Data/DataForm")),
  },

  {
    path: "data",
    element: lazy(() => import("../../pages/Data/DataType")),
  },

  {
    path: "bills-payment",
    element: lazy(() => import("../../pages/Bills/BillType")),
  }, //statement-request

  {
    path: "statement-request",
    element: lazy(() => import("../../pages/Statement/StatementForm")),
  }, //
  {
    path: "bills-form",
    element: lazy(() => import("../../pages/Bills/BillForm")),
  },

  {
    path: "transfer-form",
    element: lazy(() => import("../../pages/Transfers/TransferForm")),
  },

  {
    path: "transfer-type",
    element: lazy(() => import("../../pages/Transfers/TransferType")),
  },
  {
    path: "all-claims",
    element: lazy(() => import("../../components/Claims/AllClaims")),
  },
  {
    path: "claim-policy",
    element: lazy(() => import("../../components/Claims/ClaimPolicy")),
  },
  {
    path: "list-claims",
    element: lazy(() => import("../../components/Claims/ListClaims")),
  },
  {
    path: "form-claims",
    element: lazy(() => import("../../components/Claims/ClaimForm")),
  },
  {
    path: "extra-product-info",
    element: lazy(() =>
      import("../../components/Extra Products Info/ExtraProductInfo.js")
    ),
  },
  {
    path: "payments",
    element: lazy(() => import("../../components/Payments/Payments")),
  },
  {
    path: "payment-success",
    element: lazy(() => import("../../components/Payments/PaymentSuccess")),
  },
  {
    path: "transaction/:transactionId",
    element: lazy(() => import("../../components/Payments/Transaction")),
  },

  {
    path: "pay/with-account",
    element: lazy(() =>
      import("../../components/Payment Methods/Pages/PayWithAccount.js")
    ),
  },
  {
    path: "pay/with-card",
    element: lazy(() =>
      import("../../components/Payment Methods/Pages/PayWithCard.js")
    ),
  },
  {
    path: "pay/with-mobile-money",
    element: lazy(() =>
      import("../../components/Payment Methods/Pages/PayWithMobileMoney.js")
    ),
  },
  {
    path: "pay/payment-otp",
    element: lazy(() =>
      import("../../components/Payment Methods/Pages/PaymentOtp.js")
    ),
  },

  {
    path: "loan/types",
    element: lazy(() => import("../../pages/Loan/LoanType")),
  },
];

export const ProtectedAdminRoutes = [
  // Admin routes
  {
    path: "admin-dashboard",
    element: lazy(() => import("../../components/Dashboard/Dashboard.jsx")),
  },
  {
    path: "customers",
    element: lazy(() => import("../../pages/Admin/Customers/Customer.js")),
  },
  {
    path: "customers/:id",
    element: lazy(() =>
      import("../../pages/Admin/Customers/CustomerDetails.js")
    ),
  },
  {
    path: "transactions",
    element: lazy(() =>
      import("../../pages/Admin/Transactions/Transaction.js")
    ),
  },
  {
    path: "transfers",
    element: lazy(() => import("../../pages/Admin/Transfers/Trasnfer.js")),
  },
];
export const ProtectedSharedRoutes = [
  {
    path: "/settings",
    element: lazy(() =>
      import("../../pages/Settings And More/HandleAccountType")
    ),
  },
  {
    path: "/settings/security",
    element: lazy(() =>
      import("../../pages/Settings And More/Personal Settings/PersonalSecurity")
    ),
  },
  {
    path: "/settings/security/change-password",
    element: lazy(() =>
      import("../../pages/Settings And More/Personal Settings/ChangePassword")
    ),
  },
  {
    path: "/settings/security/change-pin",
    element: lazy(() =>
      import("../../pages/Settings And More/Personal Settings/ChangePin")
    ),
  },
  {
    path: "/settings/security/setup-pin",
    element: lazy(() => import("../../components/Dashboard/pin/SetUpNewPin")),
  },
  {
    path: "/settings/security/edit-question",
    element: lazy(() =>
      import("../../pages/Settings And More/Personal Settings/EditQuestion")
    ),
  },
  {
    path: "/settings/security/security-questions",
    element: lazy(() =>
      import(
        "../../pages/Settings And More/Personal Settings/SecurityQuestions"
      )
    ),
  },
  {
    path: "/settings/notifications",
    element: lazy(() =>
      import("../../pages/Settings And More/Personal Settings/Notifications")
    ),
  },
  {
    path: "/settings/profile",
    element: lazy(() =>
      import("../../pages/Settings And More/Personal Settings/Profile")
    ),
  },
  {
    path: "/settings/security/otp-verification",
    element: lazy(() => import("../otp-verification/OtpVerification")),
  },
  {
    path: "/settings/all-users",
    element: lazy(() =>
      import("../../pages/Settings And More/Corporate Settings/AllUsers")
    ),
  },
  {
    path: "/settings/manage-user/:mode",
    element: lazy(() =>
      import("../../pages/Settings And More/Corporate Settings/ManageUser")
    ),
  },
  {
    path: "/validate-user",
    element: lazy(() =>
      import("../../pages/Settings And More/Corporate Settings/ValidateUser.js")
    ),
  },
  {
    path: "/validate-user/security-features",
    element: lazy(() =>
      import(
        "../../pages/Settings And More/Corporate Settings/SecurityFeatures.js"
      )
    ),
  },
];
