import React, { useRef, useState, useEffect } from "react";
import { Carousel, Empty } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "./styles/Dashboard.css";

const DashboardCarouselPrevArrow = ({ onClick }) => (
  <div
    className='absolute top-1/2 transform -translate-y-1/2 z-10 cursor-pointer w-10 h-10 rounded-full bg-[#e5791b] text-white flex items-center justify-center left-[-12px]'
    onClick={onClick}
  >
    <LeftOutlined className='text-lg' />
  </div>
);

const DashboardCarouselNextArrow = ({ onClick }) => (
  <div
    className='absolute top-1/2 transform -translate-y-1/2 z-10 cursor-pointer w-10 h-10 rounded-full bg-[#e5791b]  text-white flex items-center justify-center right-[-1px]'
    onClick={onClick}
  >
    <RightOutlined className='text-lg' />
  </div>
);

const DashboardCarousel = ({ slides }) => {
  const carouselRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slidesToShow, setSlidesToShow] = useState(3);
  const [slidesToScroll, setSlidesToScroll] = useState(3);
  const [showArrows, setShowArrows] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      let newSlidesToShow = 3;
      let newSlidesToScroll = 3;
      let shouldShowArrows = true;

      if (width < 480) {
        newSlidesToShow = 1;
        newSlidesToScroll = 1;
        shouldShowArrows = false;
      } else if (width < 768) {
        newSlidesToShow = 2;
        newSlidesToScroll = 2;
        shouldShowArrows = false;
      } else if (width < 1200) {
        newSlidesToShow = 2;
        newSlidesToScroll = 2;
        shouldShowArrows = false;
      }

      if (slides.length < newSlidesToShow) {
        newSlidesToShow = slides.length;
        newSlidesToScroll = slides.length;
        shouldShowArrows = false;
      }

      setSlidesToShow(newSlidesToShow);
      setSlidesToScroll(newSlidesToScroll);
      setShowArrows(shouldShowArrows);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [slides]);

  const handlePrevClick = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
      setCurrentIndex(currentIndex - slidesToScroll);
    }
  };

  const handleNextClick = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
      setCurrentIndex(currentIndex + slidesToScroll);
    }
  };

 return (
  <div className='relative'>
      <Carousel
        ref={carouselRef}
        className='custom-carousel'
        draggable={slides.length > slidesToShow}
        slidesToShow={slidesToShow}
        slidesToScroll={slidesToScroll}
        arrows={true}
        beforeChange={(current, next) => setCurrentIndex(next)}
      >
        {slides.map((slide, slideIndex) => (
          <div key={slideIndex} className='flex justify-center'>
            <div className='w-full'>{slide}</div>
          </div>
        ))}
      </Carousel>
      {showArrows && currentIndex > 0 && (
        <DashboardCarouselPrevArrow onClick={handlePrevClick} />
      )}
      {showArrows && currentIndex < slides.length - slidesToShow && (
        <DashboardCarouselNextArrow onClick={handleNextClick} />
      )}
  </div>
);
};

export default DashboardCarousel;
