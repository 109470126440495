import React from "react";
import { Progress, Card } from "antd";

const formatDate = (dateString) => {
  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date");
    }
    return date.toISOString().split("T")[0];
  } catch (error) {
    console.error("Error formatting date:", error);
    return "Invalid date";
  }
};

const DepositCard = ({ policy, onClick }) => {
  const isMotorInsurance = policy.type === "motor";

  return (
    <div className="pr-4">
      <Card
        className="relative w-[280px] h-[140px] bg-[#E5791B] rounded-lg cursor-pointer mb-2 shadow-[rgba(0,0,0,0.08)_0px_6px_16px_-8px] flex flex-col justify-between"
        onClick={onClick}
      >
        <div>
          <img
            src="/vector3.png"
            alt="Vector3"
            className="absolute top-[4.5px] left-0 w-[429.5px] h-[134px] opacity-65"
          />
          {/* {
    "accountClass": 0,
    "accountType": "Public sector Loan",
    "accountNumber": "99399030400",
    "accountHolder": "Owa Oluwasegun  ",
    "accountStatus": "Active",
    "dateOpened": "2024-08-13T19:53:55.5089784-05:00",
    "loanDuration": "12 Months",
    "loanAmount": 10000,
    "repaymentAmount": 2000,
    "interestAmount": 7500,
    "currency": "NGN",
    "category": "loan",
    "type": "loans",
    "active": false
} */}
          <img
            src="/vector1.png"
            alt="Vector1"
            className="absolute top-[4.5px] left-0 w-[429.5px] h-[134px] opacity-65"
          />

          {/* Duplicate img removed for cleaner code */}

          <div className="flex items-center mb-1 w-full">
            <h5 className="text-base font-bold text-white font-open-sans overflow-hidden whitespace-nowrap text-ellipsis">
              {isMotorInsurance
                ? "Motor Insurance"
                : `${policy.type.charAt(0).toUpperCase()}${policy.type.slice(1)}`}
            </h5>

          </div>

          <div className="mb-4 pb-4">
            <p className="text-xs font-normal text-white font-open-sans leading-[16px]">
              {parseFloat(policy.category == 'loan' ? policy.loanAmount : policy.accountBalance).toLocaleString("en-NG", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              {policy.currency}
            </p>
          </div>
        </div>

        {/* <div className="mt-auto">
          <p className="text-xs my-2 font-normal text-white font-open-sans leading-[16px]">
            {policy.accountNumber}
            <span className="mx-1 w-[6px] h-[6px] bg-white text-center font-open-sans text-[14px] leading-[20px] rounded-full"></span>
            {policy.accountType}
          </p>
        </div> */}

        <div className="flex items-center mt-3 mb-1 w-full">
          <h6 className="text-white font-semibold text-sm font-open-sans overflow-hidden whitespace-nowrap text-ellipsis">
            {policy.accountNumber}
          </h6>
          <span className="mx-1 w-[6px]  h-[6px] bg-white text-center font-open-sans text-[14px] leading-[20px] rounded-full"></span>
          <p className="text-sm font-semibold text-white font-open-sans">
            {policy.accountType}
          </p>
        </div>
      </Card>
    </div>
  );

};

export default DepositCard;
